import { render, staticRenderFns } from "./sensitivedetail.vue?vue&type=template&id=b6be6486&scoped=true&"
import script from "./sensitivedetail.vue?vue&type=script&lang=js&"
export * from "./sensitivedetail.vue?vue&type=script&lang=js&"
import style0 from "./sensitivedetail.vue?vue&type=style&index=0&id=b6be6486&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6be6486",
  null
  
)

export default component.exports