<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
            position: fixed;
            text-align: right;">
        <i @click="handleSelect(gobackurl)" class="el-icon-close"></i>
      </div>
      <div class="pt20"></div>
      <div class="timediv">生成时间：{{form.updated_at}}</div>
      <div>
      <div class="jiben fle">
        <div style="display: flex;" class="bgl">
          <h4 style="margin: 0;line-height: 28px;">敏感词信息</h4>
        </div>
        <div >
          <div>敏感词内容：<input type="text" disabled style="width:770px;" v-model="form.name"></div>
        </div>
        <div style="display: flex;">
          <div>机审结果：<input type="text" disabled style="width:350px;" v-model="form.conclusion"></div>
          <div>违规原因：<input type="text" disabled style="width:350px;" v-model="form.msg"></div>
        </div>

        </div>
    <div class="form">
      <h4 class="bgl" style="text-align: left;font-size: 14px;color: #606266;">处理信息</h4>
      <el-form ref="form" :model="form" >
      
        <el-form-item  :required="true" label="处理结果">
          <el-select  :disabled='look'
             v-model="form.status2"  @change="refreshStatus" placeholder="请选择">
            <el-option
              v-for="item in opstatus"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        
      <el-form-item>
        <el-button type="primary" v-if="!look" style="margin-left: 45%;margin-top: 20px;" @click="onSubmit">提交</el-button>
      </el-form-item>
    </el-form>
</div>
</div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'

 
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
        checkAll: false,
        checkedCities: [],
        cities: [],
        isIndeterminate: true,
      drawer: false,
      gobackurl:'/sensitive/sensitivelist',
      id:0,
      type:1,
      look:false,
      checkeds:false,
      form: {
        report_user:[],
        status:'',
        status2:'',
        article:{
          content:'',
          id:'',
          images:[{images_url:''}],
          is_recommend:0,
          label:[],
          title:'',
          topic_id:'',
          topic:{
            id:'',
            topic_examine:'',
            name:'',
          },
        }
        },
        options:[],
        opstatus:[
          {
            value: 2,
            label: '审核通过'
          },
          {
            value: 3,
            label: '审核未通过'
          }
        ],
        opviolation:[
          {
            value: '1',
            label: '轻度'
          },
          {
            value: '2',
            label: '重度'
          },
          {
            value: '3',
            label: '删除'
          }
        ],
        zidingyi:'',
        btnshow:false,
        btnshow2:false,
        btnshow3:false
    }
  },
  methods: {
      refreshStatus(){
        this.$forceUpdate()
      },
    handleSelect(url){
        this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = {
        id:_this.form.id,
        status:_this.form.status2,
        updated_at:_this.form.updated_at,
      };
      if(postdata.status != 2 && postdata.status != 3){
        _this.$message.error('审核结果必选');
        return;
      }
      axios.post(config.mg,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav(_this.gobackurl);
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
      shouqi(){
        this.btnshow = !this.btnshow;
      },
      shouqi2(){
        this.btnshow2 = !this.btnshow2;
      },
      shouqi3(){
        this.btnshow3 = !this.btnshow3;
      },
    init(a){
        let _this = this;
        let obj = JSON.parse(a);
        this.type = obj.type;
        this.look = obj.look;
        axios.get(config.mg,{params:{id:obj.id}})
        .then(function (response) {
            if(response.data.code == 200){
                _this.form = response.data.data;
                if(_this.form.status == 2 || _this.form.status == 3){
                  _this.form.status2 = _this.form.status;
                }
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
  }
}
</script>
<style scoped>
.el-form {
    text-align: left;
}
.jiben{
    text-align: left;
}
.jiben div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}
.fle div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 5px 10px 5px 0;
    box-sizing: border-box;
    font-weight: bold;
}
.flex{
  display: flex;
}
.le{
  margin-left: 50px;
}
.mj{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
}
.ww{
  margin-left: 10px;
}
.lef{
    margin-left: 20px;
}
.dageda{
    display: flex;
}

.el-icon-close:before{
  font-size: 24px;
}
.jiben .bgl{
    background-color: aliceblue;
    padding: 10px;
    margin-bottom: 5px;
    margin-top: 0;
}.bgl{
    background-color: aliceblue;
    padding: 10px;
    margin-bottom: 5px;
    margin-top: 0;
}
.timediv{
    text-align: right;
    padding-right: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.fle div input{
  height: 20px;
}
</style>
